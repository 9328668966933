import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import logo from '../assets/logo-navbar.png';
import { getUser , removeUserSession } from '../utils/Common';

function Navigate() {
  const history = useHistory();
  const [userDetail, setUserDetail] = useState(null);

  const handleLogout = async () => {
    try {
      removeUserSession();
      history.push('/');
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  useEffect(() => {
    const userData = getUser ();
    if (userData) {
      setUserDetail(userData);
    }
  }, []); // Empty array to run effect only once

  const openInNewTab = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div>
      <Navbar style={{ fontSize: '12px' }} bg="dark" variant="dark" expand="sm">
        <Container>
          <Navbar.Brand>
            <img width={100} src={userDetail ? userDetail.img_logo : logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link onClick={() => history.push('/dashboard')}>Home</Nav.Link>
              <Nav.Link disabled onClick={() => history.push('/config')}>Configuration</Nav.Link>
              <NavDropdown title="Setting" id="basic-nav-dropdown">
                <NavDropdown.Item disabled onClick={() => history.push('/fac/list')}>Facilities</NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push('/sch/list')}>Schedule</NavDropdown.Item>
                <NavDropdown.Item disabled onClick={() => history.push('/gallery/list')}>Gallery</NavDropdown.Item>
                <NavDropdown.Item disabled onClick={() => history.push('/promotion/list')}>Promo & Deals</NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav className="ml-auto">
              <NavDropdown title={`Signed in as: ${userDetail ? userDetail.name : ''}`} id="user-nav-dropdown">
                <NavDropdown.Item onClick={() => openInNewTab('/profile')}>Profile</NavDropdown.Item>
                <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Navigate;